import React, {useEffect, useState}                 from 'react'
import Layout                                       from '../components/shared/layout'
import {graphql}                                    from 'gatsby'
import {Button, Container, Grid, Theme, Typography} from '@material-ui/core'
import theme                                        from '../theme'
import PortableText                                 from '../components/portableText'
import {makeStyles}                                 from '@material-ui/core/styles'
import moment                                       from 'moment'
import {EventItem}                                  from '../pages/events'
import SanityImage                                  from 'gatsby-plugin-sanity-image'

export const query = graphql`
    query EventQuery($id: String!) {
        event: sanityEvent(_id: { eq: $id }) {
            _rawBody(resolveReferences: {maxDepth: 5})
            title
            slug {
                current
            }
            registrationButton {
                buttonText
                url
            }
            publishedAt
            startDateTime
            endDateTime
            mainImage {
                ...ImageData
            }
        }

        placeholderImage: sanitySiteSettings {
            siteMedia {
                eventPageDefaultImage {
                    ...ImageData
                }
            }
        }

    }
`

interface EventItemPageProps {
    data: { event: EventItem, placeholderImage: { asset: { fluid: any } } }
}

const useStyles = makeStyles((theme: Theme) => ({
    article: {},
    bookNowBtn: {
        background: '#3A3737',
        color: '#FFF',
        marginTop: '1rem',
        width: '240px',
        '&:hover': {
            background: '#3A3730'
        }
    },
    articleTitle: {
        fontWeight: 500,
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem'
        }
    },
    headerContainer: {
        position: 'relative'
    },
    headerText: {
        position: 'absolute',
        background: '#FFF',
        padding: '2rem',
        top: '22%',
        left: 0,
        maxWidth: '80%',
        [theme.breakpoints.down('md')]: {
            maxHeight: '300px'
        }


    },
    byline: {},
    dateLine: {
        margin: 0,
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    bodyWrapper: {
        background: '#FFF'
    },
    bodyContainer: {
        backgroundColor: '#FFF',
        paddingTop: '2em'
    }
}))

const EventItemPage = (props: EventItemPageProps) => {

    const classes = useStyles(theme)
    const [eventItem, setEventItem] = useState<EventItem>()

    // console.log(props)

    useEffect(() => {
        if (props.data.event) {
            setEventItem(props.data.event)
        }
    }, [])

    return (
        <Layout backgroundColor={'#FFF'} helmetData={{
            pageTitle: eventItem ? eventItem.title : '',
            metaDescription: '',
            slug: eventItem ? `events/${eventItem.slug.current}` : 'events'
        }} backgroundImage={''}>
            {
                eventItem && (
                              <header className={classes.headerContainer}>
                                  {
                                      eventItem.mainImage ? (
                                          <SanityImage
                                              height={600}
                                              {...eventItem.mainImage}
                                              style={{
                                                  width: '100%',
                                                  height: '100%',
                                                  objectFit: 'cover',
                                                  maxHeight: '600px',
                                                  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
                                              }}
                                              imgStyle={{
                                                  maxHeight: '600px',
                                                  position: 'absolute',
                                                  left: '0',
                                                  right: '0',
                                                  marginLeft: 0,
                                                  marginRight: 0
                                              }}/>
                                      ) : (
                                          <SanityImage
                                              height={600}
                                              {...props.data.placeholderImage.siteMedia.eventPageDefaultImage}
                                              style={{
                                                  maxHeight: '600px',
                                                  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
                                              }}
                                              imgStyle={{
                                                  maxHeight: '600px',
                                                  position: 'absolute',
                                                  left: '0',
                                                  right: '0',
                                                  marginLeft: 0,
                                                  marginRight: 0
                                              }}/>
                                      )
                                  }
                                  <section className={classes.headerText}>
                                      <p className={classes.dateLine}>
                                          Published {moment(eventItem.publishedAt).format('D MMMM YYYY, HH:mm')}
                                      </p>
                                      <Typography variant={'h1'} component={'h1'} className={classes.articleTitle}>
                                          {eventItem.title}
                                      </Typography>
                                      <Typography variant={'body1'} component={'p'}>
                                          <strong>Event Date: </strong>
                                          {moment(eventItem.startDateTime).format('D MMMM YYYY')}<br/>
                                          <strong>Time: </strong>{moment(eventItem.startDateTime).format('HH:mm')}
                                      </Typography>
                                      {
                                          eventItem.registrationButton && (
                                                                           <Button href={eventItem.registrationButton.url} target={'_blank'}
                                                                                   className={classes.bookNowBtn} size={'large'} component={'a'}>
                                                                               {eventItem.registrationButton.buttonText ? eventItem.registrationButton.buttonText : 'Register'}
                                                                           </Button>
                                                                       )
                                      }
                                  </section>
                              </header>
                          )
            }
            <Container style={{backgroundColor: '#FFF'}} maxWidth={'lg'}>
                <Grid container justify={'center'}>
                    <Grid item xs={8}>
                        {
                            eventItem && (
                                          <section className={classes.bodyContainer}>
                                              <article
                                                  className={classes.article}>
                                                  <PortableText blocks={eventItem._rawBody}/>
                                                  {
                                                      eventItem.registrationButton && (
                                                                                       <Button href={eventItem.registrationButton.url} target={'_blank'}
                                                                                               className={classes.bookNowBtn} size={'large'} component={'a'}>
                                                                                           {eventItem.registrationButton.buttonText ? eventItem.registrationButton.buttonText : 'Register'}
                                                                                       </Button>
                                                                                   )
                                                  }
                                              </article>
                                          </section>
                                      )
                        }
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

export default EventItemPage
